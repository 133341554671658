<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div v-for="(permissions, group) in groupedPermissions" :key="group" class="group">
        <div class="group-title">{{ group }}</div>
        <div class="permissions-row">
          <div class="card-column" v-for="permission in permissions" :key="permission.id">
            <div class="card flex flex-wrap gap-3">
              <div class="flex align-items-start">
                <Checkbox
                  v-model="rights"
                  :name="permission.name"
                  :value="permission.id"
                />
                <label for="ingredient1" class="ml-2">
                  {{ permission.descriptions }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
export default {
  setup: () => ({ v$: useVuelidate() }),

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {
          id: 0,
          name: '',
          permissions: [],
        }
      },
    },
    listPermission: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      submitted: false,
      rights: [],
    }
  },
  computed: {
    groupedPermissions() {
      const grouped = this.listPermission.reduce((acc, permission) => {
        const group = permission.group.trim(); 
        if (!acc[group]) {
          acc[group] = [];
        }
        acc[group].push(permission);
        return acc;
      }, {});
      
      Object.keys(grouped).forEach(group => {
        grouped[group].sort((a, b) => a.descriptions.localeCompare(b.descriptions));
      });

      return grouped;
    }
  },
  mounted() {
    this.updateRightsFromPermissions()
  },
  watch: {
    'item.permissions': 'updateRightsFromPermissions',
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true
      if (!isFormValid) {
        return
      }
      const data = {}
      data.role_id = this.item.id
      data.permissions = this.rights

      this.$emit('save', data)
    },
    updateRightsFromPermissions() {
      this.rights = this.listPermission
        .filter((permission) =>
          this.item.permissions.some((p) => p.id === permission.id)
        )
        .map((permission) => permission.id)
    },
  },
}
</script>

<style scoped>
.group {
  margin-bottom: 20px; 
}

.group-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.permissions-row {
  display: flex;
  flex-wrap: wrap;
}

.card-column {
  flex: 0 0 calc(100% / 3); /* Three columns */
  padding: 0 10px; /* Adjust spacing between columns */
}

.align-items-start {
  align-items: flex-start; /* Align items to the start (left) */
}
</style>
