<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Role Permission</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Master / Data Role Permission</span>
      </div>
    </div>
    <div class="card bg-white">
      <fx-table
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        :filters="filters"
        @request="onRequestData"
        @sort="onSort($event)"
      >
        <template #columns>
          <Column field="name" header="Nama Role" style="min-width: 15rem">
          </Column>
          <Column
            field="descriptions"
            header="Deskripsi"
            style="min-width: 15rem"
          >
          </Column>
          <Column style="flex-grow: 1; justify-content: flex-end">
            <template #body="{ data }">
              <Button
                type="button"
                icon="pi pi-pencil"
                class="p-button-outlined p-button-secondary p-button-text mr-2"
                @click="onEditData(data)"
              />
            </template>
          </Column>
        </template>
      </fx-table>
    </div>
    <Dialog
      :header="form && form.id > 0 ? 'Edit Permission' : 'Tambah Permission'"
      v-model:visible="dialog"
      class="p-overflow-hidden"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '95vw' }"
      modal
      maximizable
    >
      <form-Role
        :item="form"
        :loading="isLoadingSave"
        :list-permission="list_permission"
        :editMode="editMode"
        @save="onSaveData"
        @close="dialog = false"
      />
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import { inject } from 'vue'
import UserService from '@/services/UserService'
import RoleService from '@/services/RoleService'
import PermissionService from '@/services/PermissionService'
import FormRole from '@/components/master/FormRole'
import FxTable from '@/components/FxTable'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'
import { FilterMatchMode } from 'primevue/api'
import defineAbilitiesFor from '@/services/rules'

export default {
  name: 'Role',
  components: {
    FormRole,
    FxTable,
    Hotkey,
  },
  data() {
    return {
      UserService: null,
      RoleService: null,
      PermissionService: null,
      dialog: false,
      dialogHapus: false,
      isLoading: false,
      isLoadingSave: false,
      editMode: false,
      ability: inject('ability'),
      user: {},
      items: [],
      permissions: [],
      list_permission: [],
      form: {
        id: null,
        name: null,
        descriptions: null,
        permission: [],
      },
      Role: [],
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'name',
        sortOrder: '1',
        filters: null,
      },
      filters: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        descriptions: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      containModesOption: [
        { label: 'Contains', value: FilterMatchMode.CONTAINS },
      ],
      equalModesOption: [{ label: 'Equals', value: FilterMatchMode.EQUALS }],
    }
  },
  created() {
    this.UserService = new UserService()
    this.RoleService = new RoleService()
    this.permissionService = new PermissionService()
  },
  async mounted() {
    this.loadData()
    this.permissionService
      .get()
      .then((res) => {
        this.list_permission = res.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Role Permission', this)
      })
  },
  methods: {
    onRequestData(e) {
      this.options = e
      this.loadData()
    },
    loadData() {
      this.isLoading = true
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.RoleService.paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data
          this.totalRecords = res.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data Role Permission', this)
        })
        .finally(() => (this.isLoading = false))
    },
    updatePermission() {
      const user = localStorage.getItem('user')
      const userObject = JSON.parse(user)
      const userId = userObject.id

      this.UserService.getById(`/${userId}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.user = res.data.data
            this.permissions = []

            this.user.roles.forEach((role) => {
              this.permissions.push(
                ...role.permissions.map((permission) => permission.name)
              )
            })
            this.ability.update(defineAbilitiesFor(this.permissions))
          }
        })
        .catch((err) => {
          errorHandler(err, 'Data Role Permission', this)
        })
    },
    onSort(e) {
      this.options.value = e
      this.loadData()
    },
    onEditData(data) {
      this.editMode = true
      this.form = Object.assign({}, data)
      this.dialog = true
    },
    onSaveData(data) {
      this.isLoadingSave = true
      this.permissionService
        .assign(data)
        .then((res) => {
          if (res.data.status === 1) {
            this.loadData()
            this.updatePermission()
            this.$toast.add({
              severity: 'success',
              summary: 'Data Permission',
              detail: 'Data berhasil disimpan.',
              life: 3000,
            })
            this.dialog = false
          }
        })
        .catch((err) => {
          errorHandler(err, 'Data Role', this)
        })
        .finally(() => (this.isLoadingSave = false))
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onAddData()
      }
    },
  },
}
</script>
